<template>
  <div>
    <a-row>
      <a-col :span="22">
        <a-form-model
          layout="inline"
          :colon="false"
          @keyup.enter.native="query"
        >
          <a-form-model-item>
            <a-input v-model="form.deptCode" placeholder="编码" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.uniqueName" placeholder="名称" />
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.type"
              placeholder="类型"
            >
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-radio-group v-model="form.status">
              <a-radio
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-radio
              >
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="2">
        <div class="right">
          <a-button
            v-if="$getPermission($route.path + '/add')"
            @click="$router.push($route.path + '/add')"
            type="primary"
            >创建组织</a-button
          >
        </div>
      </a-col>
    </a-row>

    <Padding />

    <a-table
      v-if="list.length > 0"
      bordered
      :dataSource="list"
      :pagination="false"
      childrenColumnName="orgs"
      :indentSize="24"
      :defaultExpandAllRows="true"
      rowKey="id"
    >
      <a-table-column title="名称">
        <template slot-scope="text">
          <a
            href="#"
            v-if="$getPermission($route.path + '/detail')"
            @click.prevent="$router.push($route.path + '/detail?id=' + text.id)"
            >{{ text.uniqueName }}</a
          >
          <span v-else>{{ text.uniqueName }}</span>
        </template>
      </a-table-column>

      <a-table-column
        title="编码"
        data-index="deptCode"
        align="center"
      ></a-table-column>
      <a-table-column title="组织类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="orgType" :dictValue="text.type" />
        </template>
      </a-table-column>

      <a-table-column
        title="负责人"
        data-index="master2Name"
        align="center"
      ></a-table-column>

      <a-table-column
        title="分管领导"
        data-index="chargerName"
        align="center"
      ></a-table-column>

      <a-table-column title="状态" data-index="status" align="center">
        <template slot-scope="status">
          <DataDictFinder dictType="status" :dictValue="String(status)" />
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a
              href="#"
              v-if="$getPermission($route.path + '/detail')"
              @click.prevent="
                $router.push($route.path + '/detail?id=' + text.id)
              "
              >详情</a
            >
            <a
              href="#"
              v-if="$getPermission($route.path + '/detail')"
              @click.prevent="
                $router.push($route.path + '/honor?id=' + text.id)
              "
              >荣誉</a
            >
            <a
              href="#"
              v-if="$getPermission($route.path + '/edit')"
              @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a
            >
            <a
              href="#"
              v-if="$getPermission($route.path + '/edit')"
              @click.prevent="
                $router.push($route.path + '/group-list?id=' + text.id)
              "
              >所组</a
            >
            <a
              href="#"
              class="danger"
              v-if="$getPermission($route.path + '/delete')"
              @click.prevent="deleteText(text)"
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { remove } from "@/api/setting/organization";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
    };
  },

  computed: {
    ...mapState("humanResources", ["list"]),
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
    typeList() {
      return this.findDataDict("orgType");
    },

    defaultExpandedRowKeys() {
      return [];
    },
  },

  methods: {
    // getIdList(list) {
    //   list.forEach(item => {
    //     if (Array.isArray(item.orgs)) {
    //       this.getIdList(item.orgs)
    //     }
    //   });
    // },

    ...mapActions("humanResources", ["getList"]),

    query() {
      this.getList(this.form);
    },
    reset() {
      this.getList();
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个组织吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>