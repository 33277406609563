<template>
  <div style="display: flex; height: 65vh">
    <div id="org-tree" style="flex: 1"></div>
  </div>
</template>

<script>
import { Graph, Dom } from "@antv/x6";
import dagre from "dagre";
import insertCss from 'insert-css'
import { mapState } from 'vuex'

insertCss(`
  .x6-cell {
    cursor: pointer;
  }
`)

export default {

  computed: {
    ...mapState('humanResources', ['list'])
  },

  mounted() {
    if (this.list.length > 0) {
      this.initChart();
    }
  },

  destroyed() {
    this.graph?.dispose();
  },

  watch: {
    list(newValue, oldValue) {
      if (this.list.length > 0 && newValue !== oldValue) {
          this.graph?.dispose();
        this.initChart();
      }
    },
  },

  methods: {
    initChart() {
      // 自定义节点
      Graph.registerNode(
        "org-node",
        {
          width: 50,
          height: 180,
          markup: [
            {
              tagName: "rect",
              attrs: {
                class: "card",
              },
            },

            {
              tagName: "text",
              attrs: {
                class: "name",
              },
            },
          ],
          attrs: {
            ".card": {
              rx: 10,
              ry: 10,
              refWidth: "100%",
              refHeight: "100%",
              fill: "#5F95FF",
              pointerEvents: "visiblePainted",
            },

            ".name": {
              fill: "#fff",
              fontSize: 20,
              fontWeight: "600",

              refX: "50%",
              refY: "50%",
              textAnchor: "middle",
              textVerticalAnchor: "middle",

              "writing-mode": "vertical-rl",
              "letter-spacing": ".2em",
            },
          },
        },
        true
      );

      // 自定义边
      Graph.registerEdge(
        "org-edge",
        {
          zIndex: -1,
          attrs: {
            line: {
              strokeWidth: 2,
              stroke: "#A2B1C3",
              sourceMarker: null,
              targetMarker: null,
            },
          },
        },
        true
      );

      // 布局方向
      const dir = "TB"; // LR RL TB BT

      // 创建画布
      const graph = new Graph({
        container: document.getElementById("org-tree"),
        panning: true,
        mousewheel: {
          enabled: true,
        },
        selecting: true,
        // scroller: true,
        // interacting: true,
        // width: 1200,
        // height: 500,
        autoResize: true,
      });

      const that = this;

      graph.on("cell:selected", (args) => {
        that.onClick(args);
      });

      this.graph = graph;

      // 自动布局
      function layout() {
        const nodes = graph.getNodes();
        const edges = graph.getEdges();
        const g = new dagre.graphlib.Graph();
        g.setGraph({ rankdir: dir, nodesep: 4, ranksep: 4 });
        g.setDefaultEdgeLabel(() => ({}));

        const width = 60;
        const height = 240;
        nodes.forEach((node) => {
          g.setNode(node.id, { width, height });
        });

        edges.forEach((edge) => {
          const source = edge.getSource();
          const target = edge.getTarget();
          g.setEdge(source.cell, target.cell);
        });

        dagre.layout(g);

        graph.freeze();

        g.nodes().forEach((id) => {
          const node = graph.getCell(id);
          if (node) {
            const pos = g.node(id);
            node.position(pos.x, pos.y);
          }
        });

        edges.forEach((edge) => {
          const source = edge.getSourceNode();
          const target = edge.getTargetNode();
          const sourceBBox = source.getBBox();
          const targetBBox = target.getBBox();

          if (sourceBBox.x !== targetBBox.x) {
            const gap =
              dir === "TB"
                ? targetBBox.y - sourceBBox.y - sourceBBox.height
                : -sourceBBox.y + targetBBox.y + targetBBox.height;
            const fix = dir === "TB" ? sourceBBox.height : 0;
            const y = sourceBBox.y + fix + gap / 2;
            edge.setVertices([
              { x: sourceBBox.center.x, y },
              { x: targetBBox.center.x, y },
            ]);
          } else {
            edge.setVertices([]);
          }
        });

        graph.unfreeze();
      }

      function createNode(id, name) {
        return graph.createNode({
          id,
          shape: "org-node",
          attrs: {
            ".name": {
              text: Dom.breakText(name, { width: 200, height: 45 }),
            },
          },
        });
      }

      function createEdge(sourceId, targetId) {
        return graph.createEdge({
          shape: "org-edge",
          source: { cell: sourceId },
          target: { cell: targetId },
        });
      }

      function setNodesAndEdges(parentId, list, nodes, edges) {
        list.forEach((item) => {

          if (item.type !== 'branch') {
                  nodes.push(createNode(item.id, item.uniqueName));
          if (parentId) {
            edges.push(createEdge(parentId, item.id));
          }
          if (Array.isArray(item.orgs)) {
            setNodesAndEdges(item.id, item.orgs, nodes, edges);
          }
          }

    
        });
      }

      const nodes = [];
      const edges = [];
      setNodesAndEdges("", this.list, nodes, edges);

      graph.resetCells([...nodes, ...edges]);
      layout();
      graph.zoomTo(0.4);
      graph.centerContent();
 
    },

    onClick(args) {
      this.$router.push(this.$route.path + '/detail?id=' + args.cell.id)
    },
  },
};
</script>