<template>
  <div>
    <Pane>
      <div class="center" style="flex: 1" slot="extra">
        <a-button type="primary" shape="round" @click="showList = !showList">
          {{ showList ? "树形图" : "列表" }}
        </a-button>
      </div>
    </Pane>

    <a-card class="container">
      <Tree  v-if="!showList" />
      <List  v-else />
    </a-card>
  </div>
</template>

<script>
import Tree from "./components/tree.vue";
import List from "./components/list.vue";
import { mapActions } from 'vuex'
export default {
  // name: "humanResourcesOrg",

  components: {
    Tree,
    List,
  },

  data() {
    return {
      showList: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    ...mapActions('humanResources', ['getList'])
  },
};
</script>